import React from 'react'

import Layout from 'components/Layout'
import SEO from 'components/seo'
import { navigate } from 'gatsby'
import { WrapperNotFound } from './styles'
import Button from 'components/Button'
import error from './media/NotFound.png'

function NotFoundPage() {
  return (
    <>
      <Layout>
        <SEO title='404: Not found' />
        <WrapperNotFound>
          <img 
            src={ error } 
            className='error-image' 
            alt='not found'
          />
          <h1>PAGE NOT FOUND!</h1>
          <p>
            <span>Looks like this page is missing. </span>
            <span>Don&#96;t worry through,</span>
            <span>our best man is on the case</span>
          </p>
          <Button type='warning' onClick={ () => navigate('/') }>
            GO BACK TO HOME
          </Button>
        </WrapperNotFound>
      </Layout>
    </>
  )
}

export default NotFoundPage
